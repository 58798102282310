import { isAndroid, isIos } from '../helpers/os';
import { User } from '../types/user';
import { createDebug } from '../helpers/debug';
import { debounce } from '../common/debounce';

const debug = createDebug.extend('nativeMessageService');

interface BadgeMessage {
  sender: 'badge';
  action: 'set';
  value: number;
}

interface GetPurchaseProductsMessage {
  sender: 'payment';
  action: 'products';
  value?: string;
}

interface ClickLinkMessage {
  sender: 'app';
  action: 'click';
  value: string;
}

interface BuyProductMessage {
  sender: 'payment';
  action: 'purchase';
  product: string;
}

interface ValidatePurchaseSuccessMessage {
  sender: 'payment';
  action: 'validatePurchaseSuccess';
  value: string;
}

interface ValidatePurchaseFailMessage {
  sender: 'payment';
  action: 'validatePurchaseFail';
  value: string;
}

interface ThemeMessage {
  sender: 'app';
  action: 'setTheme';
  value: string;
}

interface BackgroundLocationUpdateMessage {
  sender: 'backgroundLocationUpdate';
  action: 'success' | 'fail';
}

interface ShowInAppReviewMessage {
  sender: 'app';
  action: 'showInAppReview';
}

interface UserMessage {
  sender: 'app';
  action: 'setUser';
  value: string;
}

export interface RequestPermissionMessage {
  sender: 'app';
  action: 'requestPermission';
  value: 'location' | 'notification' | 'battery' | 'gps';
}

export interface GetRidesMessage {
  sender: 'app';
  action: 'getRides';
  value: number;
}

export interface SearchStatusMessage {
  sender: 'app';
  action: 'searchStatus';
  value: boolean;
}

export interface ExpiredTokenStatusMessage {
  sender: 'app';
  action: 'setExpiredTokenStatus';
  value: boolean;
}

export interface FilterMessage {
  sender: 'app';
  action: 'filter';
  value: string;
}

export interface DropRideMessage {
  sender: 'app';
  action: 'dropRide';
  value: string;
}

export interface NetworkRequestMessage {
  sender: 'app';
  action: 'networkRequest';
  value: string;
}

export type NativeMessage =
  | BadgeMessage
  | ClickLinkMessage
  | BuyProductMessage
  | GetPurchaseProductsMessage
  | ValidatePurchaseSuccessMessage
  | ValidatePurchaseFailMessage
  | ThemeMessage
  | UserMessage
  | ShowInAppReviewMessage
  | BackgroundLocationUpdateMessage
  | RequestPermissionMessage
  | NetworkRequestMessage
  | GetRidesMessage
  | DropRideMessage
  | SearchStatusMessage
  | ExpiredTokenStatusMessage
  | FilterMessage;

export class NativeMessageService {
  sendBadgeMessage = (value: number) => {
    if (!isIos) {
      return;
    }

    this.sendMessage<BadgeMessage>({
      sender: 'badge',
      action: 'set',
      value,
    });
  };

  sendGetPurchaseProductsMessage = (skus?: string) => {
    this.sendMessage<GetPurchaseProductsMessage>({
      sender: 'payment',
      action: 'products',
      value: skus,
    });
  };

  sendClickLinkMessage = (url: string) => {
    this.sendMessage<ClickLinkMessage>({
      sender: 'app',
      action: 'click',
      value: url,
    });
  };

  sendBuyProductMessage = (product: string) => {
    this.sendMessage<BuyProductMessage>({
      sender: 'payment',
      action: 'purchase',
      product,
    });
  };

  sendValidatePurchaseSuccessMessage = (purchase: string) => {
    this.sendMessage<ValidatePurchaseSuccessMessage>({
      sender: 'payment',
      action: 'validatePurchaseSuccess',
      value: purchase,
    });
  };

  sendValidatePurchaseFailMessage = (data: string) => {
    this.sendMessage<ValidatePurchaseFailMessage>({
      sender: 'payment',
      action: 'validatePurchaseFail',
      value: data,
    });
  };

  sendThemeMessage = (value: string) => {
    this.sendMessage<ThemeMessage>({
      sender: 'app',
      action: 'setTheme',
      value,
    });
  };

  sendShowInAppReviewMessage = () => {
    this.sendMessage<ShowInAppReviewMessage>({
      sender: 'app',
      action: 'showInAppReview',
    });
  };

  sendUserMessage = (user: User | '') => {
    this.sendMessage<UserMessage>({
      sender: 'app',
      action: 'setUser',
      value: JSON.stringify(user ? { userId: user.user_id, token: user.auth_key } : ''),
    });
  };

  sendBackgroundLocationUpdateMessage = (success: boolean) => {
    this.sendMessage<BackgroundLocationUpdateMessage>({
      sender: 'backgroundLocationUpdate',
      action: success ? 'success' : 'fail',
    });
  };

  sendRequestPermissionMessage = (permission: RequestPermissionMessage['value']) => {
    this.sendMessage<RequestPermissionMessage>({
      sender: 'app',
      action: 'requestPermission',
      value: permission,
    });
  };

  // tut
  sendGetRidesMessage = debounce((timestamp: number) => {
    this.sendMessage<GetRidesMessage>({
      sender: 'app',
      action: 'getRides',
      value: timestamp,
    });
  }, 100);

  sendDropRideMessage = debounce((skeddyRideId: number, providerRideId: string) => {
    this.sendMessage<DropRideMessage>({
      sender: 'app',
      action: 'dropRide',
      value: JSON.stringify({ skeddyRideId, providerRideId }),
    });
  }, 100);

  sendSearchStatusMessage = (value: boolean) => {
    this.sendMessage<SearchStatusMessage>({
      sender: 'app',
      action: 'searchStatus',
      value,
    });
  };

  sendExpiredTokenStatusMessage = (value: boolean) => {
    this.sendMessage<ExpiredTokenStatusMessage>({
      sender: 'app',
      action: 'setExpiredTokenStatus',
      value,
    });
  };

  sendFilterMessage = (value: string) => {
    this.sendMessage<FilterMessage>({
      sender: 'app',
      action: 'filter',
      value,
    });
  };

  sendNetworkRequestMessage = ({
    url,
    method = 'get',
    headers = {},
    params = {},
    sendImmediate,
    repeatCount = 0,
    type,
  }: {
    url: string;
    headers: Record<string, string | null>;
    params?: Record<string, any>;
    method: 'get' | 'post';
    sendImmediate?: boolean;
    repeatCount?: number;
    type: string;
  }) => {
    this.sendMessage<NetworkRequestMessage>({
      sender: 'app',
      action: 'networkRequest',
      value: JSON.stringify({
        url,
        method,
        headers,
        params,
        repeatCount,
        sendImmediate,
        type,
      }),
    });
  };

  private sendMessage = <T extends NativeMessage>(message: T) => {
    debug('Send message to native app:', message);

    try {
      if (isIos && window.webkit !== undefined) {
        window.webkit.messageHandlers.observer.postMessage(message);
        return;
      }

      if (isAndroid && window.JSBridge !== undefined) {
        window.JSBridge.postMessage(JSON.stringify(message));
        return;
      }
    } catch (e) {
      console.error(e);
    }
  };
}

export const nativeMessageService = new NativeMessageService();
